<template>
  <div class="main">
    <heand :tagnum="tagnum"></heand>
    <div class="chuangx_fffbox">
      <div class="tongj_list">
        <div class="search">
          <el-input v-model="name" placeholder="请输入企业名称"></el-input>
          <el-button @click="clicksearch360">查询</el-button>
        </div>
        <dl
          v-for="(item, index) in firmdata"
          :key="index"
          @click="goDetails(item.id)"
        >
          <dd>
            <h1 v-html="item.name"></h1>
            <p v-if="classify == 1">
              所属行业： {{ item.industry }}

              <!-- <span style="margin-left: 40px">公司类型：</span>
              <span class="isfive" v-if="item.isfive">{{ item.isfive }}</span>
              <span class="isfive" v-if="item.quoted_company">{{
                item.quoted_company
              }}</span>
              <span class="isfive" v-if="item.scale">{{ item.scale }}</span>
              <span class="isfive" v-if="item.zjtg">{{ item.zjtg }}</span>
              <span class="isfive" v-if="item.high_new">{{
                item.high_new
              }}</span> -->
              <!-- <span class="isfive" v-if="item.tbe">{{ item.tbe }}</span> -->
            </p>
          </dd>
          <dt v-if="classify == 1">
            <!-- <span v-html="'主要产品：' + item.product"></span> -->
            <span>成立时间：{{ item.build_date }}</span>
            <span>注册资本：{{ item.capital }}</span>
          </dt>
          <dt v-if="classify == 2">
            <span v-html="'高校类型：' + item.category"></span>
            <span>办学性质：{{ item.nature }}</span>
            <span>创办时间：{{ item.buildate }}</span>
          </dt>
          <dt v-if="classify == 3">
            <span v-html="'机构类型：' + item.type"></span>
            <span>联系电话：{{ item.telephone }}</span>
          </dt>
          <dt v-if="classify == 4">
            <span v-html="'机构类型：' + item.type"></span>
          </dt>
        </dl>

        <!-- <div
          class="jiaz_btns"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="拼命加载中"
        >
          {{ xiala }}
        </div> -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
       <div class="backPage" @click="$router.go(-1)">返回</div>
  </div>
</template>

<script>
import {
  histogram360,
  field,
  getRegion,
  search360,
  seachhistory,
  complist,
  enterList,
} from "@/api/index";
import echarts from "echarts/lib/echarts";
import "echarts/lib/component/graphic";
export default {
  data() {
    return {
      name: "",
      classify: 1,
      total: 1,
      xiala: "下拉加载更多",
      tagnum: 2,
      firmName: "",
      firmtype: "",
      firmtypenum: [],
      firmallnum: [],
      optionstype: [], //企业类型
      type: "",
      firmtype: "",
      aptitude: "",
      optionsaptitude: [], //企业资质
      valueaptitude: "",
      capital: "",
      optionscapital: [], //注册资本
      valuecapital: "",
      valuestate: "",
      state: "",
      optionsstate: [], //上市状态
      valuestate: "",
      options: [],
      optionsfinanc: [], //成立时间
      valuefinanc: "",
      time: "",
      optionsrounds: [], //融资轮次
      valuerounds: "",
      financ: "",
      region: "",
      optionsvalue: [],
      optionsvalue2: "",
      page: 1,
      perpage: "10",
      company: "",
      firmdata: [],
      loading: true,
      historySearch: "",

      product: "",
      select: "",
      inid: "",
      area: [],
    };
  },
  mounted() {
    var that = this;
    window.addEventListener("scroll", this.handleScroll, true);
    window.onscroll = function() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //到了这个就可以进行业务逻辑加载后台数据了
        // that.page++;
        // that.gosearch360();
        console.log("到了底部");
      }
    };
  },
  created() {
    console.log(
      this.$route.query.select,
      this.$route.query.product,
      this.$route.query.inid,
      this.$route.query.area,
      99999
    );
    this.select = this.$route.query.select;
    this.product = this.$route.query.product;
    this.inid = this.$route.query.inid;
    this.area = this.$route.query.area;

    this.gosearch360();
  },
  methods: {
    goDetails(id) {
      console.log(id);
      let href = this.$router.resolve({
        // name: "chdetsils9",
        path: "/industrial/chdetsils9",
        query: {
          id: id,
          tagnum: 5,
        },
      });
      // window.open(href.href);
      window.location.href = href.href;
    },
    async lookcomp(e) {
      // console.log(e.currentTarget.dataset.select,this.inid,this.area)
      const res = await enterList({
        name: this.name,
        select: this.select,
        product: this.product,
        inid: this.inid,
        area: this.area,
        page: 1,
        perpage: 10,
      });
      console.log(res);
    },
    handleSizeChange(val) {
      console.log(val, "2");
      this.page = val;
      this.gosearch360();
      this.toTop();
    },
    handleCurrentChange(val) {
      console.log(val, "33");
      this.page = val;
      this.gosearch360();
      this.toTop();
    },
    handleScroll(e) {
      let scrolltop = e.target.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 30);
    },
    async getcomplist() {
      var classify = parseInt(this.classify);
      var type = parseInt(this.type);
      var district = this.district;
      var page = parseInt(this.page);
      var perpage = parseInt(this.perpage);
      var data = { classify, type, district, page, perpage };
      const res = await complist(data);
      console.log(res);
    },
    async seachhistory() {
      const res = await seachhistory();
      this.historySearch = res.search;
    },
    // 柱状图数据
    async gethistogram() {
      const res = await histogram360();
      this.firmtypenum = res.type;
      this.firmallnum = res.quality;
      this.picture();
    },
    // 筛选数据
    async getfield() {
      const res = await field();
      console.log(res, 9999);
      this.optionstype = res.options.entype;
      this.optionsaptitude = res.options.qualificat;
      this.optionscapital = res.options.capital;
      this.optionsstate = res.options.quoted;
      this.optionstime = res.options.district;
      this.optionsfinanc = res.options.buildate;
      this.optionsrounds = res.options.financ;
    },
    // 获取省市区
    async getRegion() {
      const res = await getRegion();
      this.options = res;
    },
    // 下拉框匹配数据
    optionschange(e, data, num) {
      if (e[0] != undefined) {
        this.optionsvalue2 = e[0] + "/" + e[1] + "/" + e[2];
      } else {
        this.optionsvalue2 = "";
      }
    },
    //搜索
    async gosearch360() {
      // this.loading = true;
      // var classify = parseInt(this.classify);
      // var type = parseInt(this.type);
      // var district = this.district;
      // var page = parseInt(this.page);
      // var perpage = parseInt(this.perpage);
      // var data = { classify, type, district, page, perpage };
      // var data = {
      //   qualificat: this.valueaptitude,
      //   entypeid: this.firmtype,
      //   area: this.optionsvalue,
      //   capital_id: this.valuecapital,
      //   public_id: this.valuestate,
      //   yearid: this.valuefinanc,
      //   company: this.company,
      //   page: this.page,
      //   per_page: this.perpage,
      //   financ_id: this.valuerounds,
      // };
      console.log(this.select);
      const res = await enterList({
        name: this.name,
        select: this.select,
        product: this.product,
        inid: this.inid,
        area: this.area,
        page: parseInt(this.page),
        perpage: parseInt(this.perpage),
      });
      // const res = await complist(data);
      console.log(res, 88);
      console.log(res.size, 666666666);
      this.loading = false;
      this.total = res.size;
      if (res.enter.length < 10) {
        this.xiala = "没有更多了";
      } else {
        this.xiala = "下拉加载更多";
      }
      this.firmdata = res.enter;
      console.log(this.firmdata, 999);
      // $("body,html").animate(
      //   {scrollTop: 0,},
      //   300
      // );
      // if (this.page == 1) {
      //   this.firmdata = res.data_list;
      // } else {
      //   for (var i = 0; i < res.data_list.length; i++) {
      //     this.firmdata.push(res.data_list[i]);
      //   }
      // }
      // this.seachhistory();
      this.loading = false;
      // this.historySearch = res.search;
    },
    clicksearch360() {
      this.page = "1";
      this.gosearch360();
    },
    // 历史搜索
    clickhistory(val) {
      this.page = "1";
      this.company = val;
      this.gosearch360();
    },
    labelchange(e, obxj, num) {
      let obj = {};
      console.log(obxj);
      switch (num) {
        case 1:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          console.log(obj);
          this.type = obj.label;
          break;
        case 2:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          console.log(obj);
          this.aptitude = obj.label;
          break;
        case 3:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.capital = obj.label;
          break;
        case 4:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.state = obj.label;
          break;
        case 5:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.region = obj.label;
          break;
        case 6:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.time = obj.label;
          break;
        case 7:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.financ = obj.label;
          break;
        // default:
        //   默认代码块;
      }
    },
    //新打开一个详情页
    goDetatils(id) {
      if (this.classify == 1) {
        // var href = this.$router.resolve({
        //   name: "chdetsils9",
        //   path: "/industrial/chdetsils9",
        //   query: {
        //     id: id,
        //     tagnum: 1,
        //     new: 2,
        //   },
        // });
        this.$router.push({
          name: "chdetsils9",
          path: "/industrial/chdetsils9",
          query: {
            id: id,
            tagnum: 1,
            new: 2,
          },
        });
      } else if (this.classify == 2) {
        // var href = this.$router.resolve({
        //   name: "chdetsils7",
        //   path: "/industrial/chdetsils7",
        //   query: {
        //     id: id,
        //     cateone: 1,
        //   },
        // });
        this.$router.push({
          name: "chdetsils7",
          path: "/industrial/chdetsils7",
          query: {
            id: id,
            cateone: 1,
          },
        });
      } else if (this.classify == 3) {
        // var href = this.$router.resolve({
        //   name: "chdetsils6",
        //   path: "/industrial/chdetsils6",
        //   query: {
        //     id: id,
        //     cateone: 2,
        //   },
        // });
        this.$router.push({
          name: "chdetsils6",
          path: "/industrial/chdetsils6",
          query: {
            id: id,
            cateone: 2,
          },
        });
      } else {
        // var href = this.$router.resolve({
        //   name: "chdetsils4",
        //   path: "/industrial/chdetsils4",
        //   query: {
        //     id: id,
        //     cateone: 3,
        //   },
        // });
        this.$router.push({
          name: "chdetsils4",
          path: "/industrial/chdetsils4",
          query: {
            id: id,
            cateone: 3,
          },
        });
      }

      // window.open(href.href, "_blank");
    },

    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main0"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "0",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "个人投资企业",
              "有限责任公司",
              "股份有限公司",
              "集体所有制",
              "合伙企业",
              "国有企业",
              "外商投资企业",
            ],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 7500,
            splitNumber: 11,
            // interval: 500, //每次增加几个
            splitLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "22",
            data: this.firmtypenum, //企业总数类型统计
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#0285FF",
                  },
                  {
                    offset: 0.9,
                    color: "#0285FF",
                  },
                ]),
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);

      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart2 = this.$echarts.init(document.getElementById("main2"));
      // 指定图表的配置项和数据
      let option2 = {
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "0",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "高新技术企业",
              "科技型中小企业",
              "上市企业",
              "独角兽企业",
              "瞪羚企业",
            ],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // interval: 1000, //每次增加几个
            splitNumber: 11,
            splitLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "40",
            data: this.firmallnum, //企业总量资质统计
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#40B2FF",
                  },
                  {
                    offset: 0.9,
                    color: "#40B2FF",
                  },
                ]),
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart2.setOption(option2);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
  font-family: "PingFangSC";
}
.portrait_ban {
  margin: 0 auto;
  background: url(../../assets/industrial/360qy_ban.png) no-repeat center;
  height: 225px;
  color: #fff;

  dl {
    padding: 65px;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1;
    dt {
      height: 60px;
      line-height: 60px;
      width: 800px;
      margin: 0 auto;
    }
    /deep/.el-input {
      float: left;
      width: 650px;
      input {
        vertical-align: top;
      }
    }
    /deep/.el-input__inner {
      width: 650px;
      height: 50px;
      line-height: 50px;
      background: #dde7ff;
      border: 1px solid #dddddd;
      padding-left: 15px;
      border-radius: 0;
    }
    /deep/.el-button--primary {
      border-radius: 0;
      background: #fe6a00;
      height: 51px;
      line-height: 51px;
      color: #fff;
      border: 0;
      padding: 0;
      width: 150px;
      font-size: 16px;
      float: left;
    }
    ::-webkit-input-placeholder {
      color: #999;
    }
    dd {
      padding-top: 15px;
      font-size: 14px;
      color: #999;
      span {
        color: #fff;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}

.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .ipt {
    padding: 10px 0 0 35px;
    margin-bottom: 10px;
    overflow: hidden;
    zoom: 1;
    li {
      display: block;
      width: 24%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 1% 1% 0 0;

      /deep/.el-select {
        width: 57%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border-radius: 0;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 100%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
      }
      ::-webkit-input-placeholder {
        color: #333;
      }
      /deep/.el-input__inner {
        padding: 0 5px;
      }
    }
  }

  .zhanshi {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0% 2% 0;
    .lilast {
      list-style: none;
      span {
        display: block;
        width: 168px;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        border: 1px solid #dddddd;
        margin-right: 10px;
        padding: 0 5px;

        dl {
          float: right;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      span:nth-child(2) {
        width: 182px;
      }
    }
  }

  .tongj_echars {
    overflow: hidden;
    zoom: 1;
  }
  .tongj_box {
    width: 45%;
    float: left;
    padding: 0 30px;
    h1 {
      font-size: 15px;
      text-align: left;
      font-weight: normal;
    }
  }
  .tongj_box2 {
    float: right;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 31px;
  background: #fe6a00;
  cursor: pointer;
  border-radius: 4px;
  line-height: 34px;
  font-size: 14px;
  margin-top: 1%;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.tongj_list {
  margin: 30px;
}
.tongj_list dl {
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.tongj_list dd {
  padding: 20px;
}
.tongj_list dd h1 {
  font-size: 18px;
  color: #fe6a00;
  font-weight: bold;
  margin-bottom: 8px;
}
.tongj_list dd p {
  font-size: 14px;
  color: #888;
}
.tongj_list dt {
  background: #f3f6fb;
  height: 32px;
  line-height: 32px;
}
.tongj_list dt span {
  display: block;
  min-width: 250px;
  margin-left: 20px;
  font-size: 14px;
  float: left;
  color: #666;
}
.jiaz_btns {
  width: 130px;
  height: 37px;
  line-height: 37px;
  padding-right: 15px;
  font-size: 14px;
  text-align: center;
  background: #f2f4f7;
  border-radius: 19px;
  margin: 0 auto;
  color: #666;
  position: relative;
}
.jiaz_btns::before {
  content: "";
  width: 7px;
  height: 7px;
  border: solid #999;
  border-width: 0 0 1px 1px;
  transform: translate(-50%, -50%) rotate(-45deg);
  position: absolute;
  right: 15px;
  top: 17px;
}
/deep/.el-cascader {
  height: 30px;
  line-height: 30px;
}
/deep/.el-input__icon {
  height: 30px;
  line-height: 30px;
}
/deep/.el-loading-spinner i,
/deep/.el-loading-spinner .el-loading-text {
  color: #666 !important;
}
.isfive {
  display: inline-block;
  margin: 0 10px;
  height: 20px;
  font-size: 14px;
  padding: 0 5px;
  color: #fff;
  line-height: 20px;
  border-radius: 2px;
  background-color: #fe6a00;
}
/deep/.el-pagination {
  text-align: center;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fe6a00;
}
.search {
  margin-bottom: 30px;
  .el-button {
    background-color: #fe6a00;
    color: #fff;
  }
}
/deep/ .el-input {
  width: 400px;
  margin-right: 30px;
}
</style>
